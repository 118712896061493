import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AWS from 'aws-sdk';
import { history } from '../history';
import { AppConfig } from "../config";
import { connect, useDispatch } from 'react-redux';

const Home = ({ user }) => {

	const dispath = useDispatch();
    const [sent, setSent] = useState(false);
    const [showError, setShowError] = useState(false);
    const [data, setData] = useState({});
    const [showLoader, setShowLoader] = useState(false);
	const [t] = useTranslation(['translation']);
	
	useEffect(() => {
	},[])

    const sendMail = (e) => {
        e.preventDefault();

        setShowError(false);
        setShowLoader(true);
        AWS.config.update({
            region: 'ap-northeast-2',
            secretAccessKey: AppConfig.MAIL_SEND_SECRET_KEY,
            accessKeyId: AppConfig.MAIL_SEND_ACCESS_KEY
        });

        const params = {
            Destination: { ToAddresses: ['support@rayteams.com'] },                
            Message: {
                Body: { Text: { Charset: "UTF-8", Data: data.message + "\n=============================================================\n from " + data.email } },
                Subject: { Charset: 'UTF-8', Data: '[RAYTeams Partner Site - Online inquiries] ' + data.name }
            },
            Source: 'support@rayteams.com'
        };

        const sendPromise = new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();

        sendPromise
            .then((data) => {
                setSent(true);
                setData({ name: '', email: '', message: '' });
            })
            .catch((err) => console.log(err))
            .finally(() => setShowLoader(false));
    }
	
  	return <div className="d-flex flex-column flex-root partner-root index" id="index-top">
		<div className="partner-page flex-column flex-column-fluid">
			<div className="partner-header">
				<div className="partner-container container-xxl d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
						<a href="/">
							<img alt="Logo" src="https://raymedical.co.kr/img/partner/bi_partner_purple.svg" className="h-30px logo" />
						</a>
					</div>
					<div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
						<div className="partner-header-menu partner-header-mobile-drawer align-items-stretch">
							<div className="menu menu-rounded menu-active-bg menu-state-primary menu-column menu-lg-row">
								<div className="menu-item ">
									<span className="menu-link">
										<span className="menu-title"><a href="#index-top">Home</a></span>
									</span>
								</div>
								<div className="menu-item">
									<span className="menu-link">
										<span className="menu-title"><a href="#contact-us">Contact us</a></span>
									</span>
								</div>
							</div>
						</div>
						<div className="partner-navbar flex-shrink-0">
							<div className="partner-navbar-item">
								<div className="btn btn-icon bg-primary btn-icon-white w-40px h-40px w-lg-50px h-lg-50px menu-dropdown" onClick={() => history.push("/login")}>
									<span className="svg-icon svg-icon-3x">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="currentColor"></path>
										</svg>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="partner-hero partner-hero-home">
				<div className="partner-container container-xxl">
					<div className="partner-hero-wrapper d-flex py-8">
						<div className="d-flex flex-column justify-content-center py-5 py-lg-10 w-100">
							<h1 className="text-white fs-4x fw-bolder letter-spacing">
								Welcome to 
							</h1>
							<h3 className="d-flex text-white fs-2hx fw-bold letter-spacing">
								RAYTeams
								<span className="ms-3 d-inline-flex position-relative">
									<span className="px-1">Partner</span>
									<img className="w-100 position-absolute bottom-0 mb-n2" src="https://raymedical.co.kr/img/partner/title-underline.svg" alt="" />
								</span>
							</h3>
							<p className="text-white fs-3 lh-2 fw-semibold pt-5 pt-lg-8">
								Everything you need for Dental Service.<br />Join the RAYTeams Partner and get started.
							</p>
						</div>
						<img src="https://raymedical.co.kr/img/partner/img_index_hero.jpg" className="partner-hero-img text-right" alt="" />
					</div>
				</div>
			</div>
			<div className="partner-contents">
				<div className="d-flex flex-column flex-column-fluid bg-light py-20 whatis">
					<div className="partner-container container-xxl partner-features">
						<div className="row g-0 ">
							<div className="col-md-6 mb-10">
								<div className="bg-white rounded-3 p-10 me-md-5 h-md-100">
									<div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-info bg-opacity-90 mb-10">
										<span className="svg-icon svg-icon-info svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M11.425 7.325C12.925 5.825 15.225 5.825 16.725 7.325C18.225 8.825 18.225 11.125 16.725 12.625C15.225 14.125 12.925 14.125 11.425 12.625C9.92501 11.225 9.92501 8.825 11.425 7.325ZM8.42501 4.325C5.32501 7.425 5.32501 12.525 8.42501 15.625C11.525 18.725 16.625 18.725 19.725 15.625C22.825 12.525 22.825 7.425 19.725 4.325C16.525 1.225 11.525 1.225 8.42501 4.325Z" fill="currentColor"></path><path d="M11.325 17.525C10.025 18.025 8.425 17.725 7.325 16.725C5.825 15.225 5.825 12.925 7.325 11.425C8.825 9.92498 11.125 9.92498 12.625 11.425C13.225 12.025 13.625 12.925 13.725 13.725C14.825 13.825 15.925 13.525 16.725 12.625C17.125 12.225 17.425 11.825 17.525 11.325C17.125 10.225 16.525 9.22498 15.625 8.42498C12.525 5.32498 7.425 5.32498 4.325 8.42498C1.225 11.525 1.225 16.625 4.325 19.725C7.425 22.825 12.525 22.825 15.625 19.725C16.325 19.025 16.925 18.225 17.225 17.325C15.425 18.125 13.225 18.225 11.325 17.525Z" fill="currentColor"></path></svg>
										</span>
									</div>
									<h1 className="mb-5">Manage devices</h1>
									<div className="fs-4 text-gray-600 py-3">
										Various settings can be made for each device. Of course, you can be monitored in various stastics.
You can also control the deivces and place orders only at specific lab.<br/>
(Use is restricted in some countries.)
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-10">
								<div className="bg-white rounded-3 p-10 ms-md-5 h-md-100">
									<div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-info bg-opacity-90 mb-10">
										<span className="svg-icon svg-icon-info svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path><path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"></path><path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"></path></svg>
										</span>
									</div>
									<h1 className="mb-5">Manage orders</h1>
									<div className="fs-4 text-gray-600 py-3">
                                        Support detailed information and statistics about your order.
You can see what orders are being placed in each labs, and various functions related to the orders.
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-10">
								<div className="bg-white rounded-3 p-10 me-md-5 h-md-100">
									<div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-info bg-opacity-90 mb-10">
										<span className="svg-icon svg-icon-info svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z" fill="currentColor"></path><path d="M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z" fill="currentColor"></path></svg>
										</span>
									</div>
									<h1 className="mb-5">Manage labs</h1>
									<div className="fs-4 text-gray-600 py-3">
										You can manage labs and create an affiliation of lab can also be managed.
You can create an account in lab or register/manage products available in each labs.
Various functions are available about lab.
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-10">
								<div className="bg-white rounded-3 p-10 ms-md-5 h-md-100">
									<div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-info bg-opacity-90 mb-10">
										<span className="svg-icon svg-icon-info svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"></path><rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"></rect><path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"></path><rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"></rect></svg>
										</span>
									</div>
									<h1 className="mb-5">Manage your organization</h1>
									<div className="fs-4 text-gray-600 py-3">
										For global partners, you can manage your partners more organically.
You can create regional branches, or you can create and manage affiliations.
If you have too many targets to manage, you can create and manage child organizations.
									</div>
								</div>
							</div>
						</div>
						<div className="row g-5 g-xl-10 partner-addon">
							<div className="col-xl-6">
								<div className="card h-lg-100 ban-signin">
									<div className="card-body pe-lg-0">    
										<div className="row">
											<div className="col-sm-6 pe-0 mb-5 mb-sm-0 pe-5">
												<div className="pt-xl-5 pb-xl-2 ps-xl-7">
													<h3 className="fs-2x fw-semibold text-white">Get started</h3>
													<span className="fs-4 fw-semibold text-white opacity-75">Please sign in to use<br />RAYTeams Partner.</span>
													<div className="mt-10">
															<div className="d-flex d-grid gap-2">
																<a onClick={() => history.push("/login")} className="btn btn-white me-lg-2 btn-orange fw-semibold">Sign in</a>
																<a href="#contact-us" className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold text-white">Help</a>
														</div>
													</div>
													<div className="fs-5 text-white opacity-75 w-100 mt-10 ">
														<span className="text-white">No account?&nbsp;&nbsp;<a href="#contact-us" className="text-white"><u>Please contact us</u></a></span>
													</div>
												</div>                     
											</div>
											<div className="col-sm-6 text-center">
												<img src="https://raymedical.co.kr/img/partner/img_ban_signin.png" className="mt-10" alt="signin-img" /> 
											</div>
										</div>        
									</div>
								</div>
							</div>
							<div className="col-xl-6">
								<div className="card h-lg-100 ban-links">
									<div className="card-body pe-lg-0">    
										<div className="row align-items-center">
											<div className="col-sm-6 pe-0 mb-5 mb-sm-0 pe-5">
												<div className="d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7">
													<div className="mb-6">
														<h3 className="fs-2x fw-semibold text-white">External Links</h3>
													</div>         
													<div className="d-flex flex-stack">
														<a href="https://www.rayteams.com/" target="_blank" className="text-white fw-semibold fs-5 me-2">RAYTeams Official</a>
														<button type="button" className="btn btn-icon btn-sm h-auto btn-color-white justify-content-end">
															<span className="svg-icon svg-icon-2">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path>
																	<rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect>
																	<path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path>
																</svg>
															</span>
														</button>
													</div>
													<div className="separator separator-dashed my-3"></div>
													<div className="d-flex flex-stack">
														<a href="http://developer.rayteams.com/" target="_blank" className="text-white fw-semibold fs-5 me-2">RAYTeams Developer</a>
														<button type="button" className="btn btn-icon btn-sm h-auto btn-color-white justify-content-end">
															<span className="svg-icon svg-icon-2">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path>
																	<rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect>
																	<path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path>
																</svg>
															</span>
														</button>
													</div>
													<div className="separator separator-dashed my-3"></div>
													<div className="d-flex flex-stack">
														<a href="https://raymedical.com/" target="_blank" className="text-white fw-semibold fs-5 me-2">Ray Official</a>
														<button type="button" className="btn btn-icon btn-sm h-auto btn-color-white justify-content-end">
															<span className="svg-icon svg-icon-2">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path>
																	<rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect>
																	<path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
											<div className="col-sm-6 text-center">
												<img src="https://raymedical.co.kr/img/partner/img_ban_links.png" className="mt-5" alt="External Links" /> 
											</div>  
										</div>        
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex flex-column flex-column-fluid bg-violet py-20 faq">
					<div className="partner-container container-xxl">
						<div className="card">
							<div className="card-body p-lg-15">
								<div className="mb-15">
									<h4 className="fs-2x text-primary w-bolder mb-6">Frequesntly Asked Questions</h4>
									<p className="fw-semibold fs-4 text-gray-600 mb-2">These are frequently asked questions. If you have any further questions, please contact us.</p>
								</div>
								<div className="row gx-md-8 gx-xl-12 gy-10">
									<div className="col-lg-6">
                                        <div className="d-flex flex-row">
                                            <span className="icon pe-none me-5 text-primary">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                                <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"></rect>
                                                </svg>
                                            </span>
                                            <div>
                                                <h4 className="mb-4 fs-3">What is the RAYTeams Partner's site for?</h4>
                                                <p className="mb-4 text-gray-800 fw-semibold fs-5">The RAYTeams partner site is for RAYTeams partner. If you have an account to access, please <a onClick={() => history.push("/login")}>sign-in</a>.</p>
                                            </div>
                                        </div>
                                    </div>
									<div className="col-lg-6">
                                        <div className="d-flex flex-row">
                                            <span className="icon pe-none me-5 text-primary">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                                <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"></rect>
                                                </svg>
                                            </span>
                                            <div>
                                                <h4 className="mb-4 fs-3">How do I become a RAYTeams partner?</h4>
                                                <p className="mb-4 text-gray-800 fw-semibold fs-5">If you want to become RAYTeam partner, please <a href="#contact-us">contact us</a>, we will contact you</p>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex flex-column flex-column-fluid bg-dark py-16 contact-us">
					<div className="partner-container container">
						<div className="card" id="contact-us">
								<div className="card-body ">
                            {
                                sent &&
                                <div className="messages">
                                    <div className="alert alert-primary alert-icon alert-dismissible fade show">
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSent(false)}></button><i className="uil uil-check-circle"></i> Thank you for your message. It has been sent.
                                    </div>
                                </div>
                            }
                            {
                                showError &&
                                <div className="messages">
                                    <div className="alert alert-danger alert-icon alert-dismissible fade show">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowError(false)}></button><i className="uil uil-times-circle"></i> You must enter all fields.
                                    </div>
                                </div>
                            }
									<div className="row mb-3">
										<div className="col-md-6 pe-lg-10">
											<form action="" className="form fv-plugins-bootstrap5 fv-plugins-framework" method="post" id="kt_contact_form">
												<h4 className="fs-2x w-bolder mb-6 text-white">Contact us</h4>
												<div className="row mb-5">
													<div className="col-md-6 fv-row fv-plugins-icon-container">
														<label className="fs-5 fw-semibold mb-2 required form-label">Name</label>
														<input type="text" className="form-control form-control-solid" placeholder="" name="name" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}  value={data.name}/>
													<div className="fv-plugins-message-container invalid-feedback"></div></div>
													<div className="col-md-6 fv-row fv-plugins-icon-container">
														<label className="fs-5 fw-semibold mb-2 required form-label">Email</label>
														<input type="text" className="form-control form-control-solid" placeholder="" name="email" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}  value={data.email}/>
													<div className="fv-plugins-message-container invalid-feedback"></div></div>
												</div>
												<div className="d-flex flex-column mb-10 fv-row fv-plugins-icon-container">
													<label className="fs-6 fw-semibold mb-2 required form-label">Message</label>
													<textarea className="form-control form-control-solid" rows="6" name="message" placeholder="" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}  value={data.message}></textarea>
												<div className="fv-plugins-message-container invalid-feedback"></div></div>
												<button className="btn btn-primary" disabled={(!data.name || !data.email || !data.message)} onClick={(e)=> sendMail(e)} >
													<span className="indicator-label">Send message</span>
													<span className="indicator-progress">Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
												</button>
											</form>
										</div>
                                        <div className="col-md-6 text-center contact-img">
                                            <img src="/img_contact_us.jpg" className="w-100" alt="contact-us" />
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<footer className="bg-navy text-inverse">
					<div className="container">
						<div className="d-md-flex align-items-center justify-content-between py-6">
							<a href="#index-top"><img src="https://raymedical.co.kr/img/partner/bi_partner_w.svg" srcSet="https://raymedical.co.kr/img/partner/bi_partner_w.svg 2x" className="logo" alt="RAYTeams Partner" /></a>
							<nav className="nav social text-md-end align-items-center">
								<p className="mb-2 mb-lg-0">(C) Ray, All rights reserved.</p>
								<a className="privacy" href="https://www.rayteams.com/privacy-policy" target="_blank">Privacy</a>
								<a className="privacy" href="https://www.rayteams.com/term-of-use" target="_blank">Terms of Use</a>
                                <a className="privacy iubenda-nostyle no-brand iubenda-embed" href="https://www.iubenda.com/privacy-policy/94780237/cookie-policy" title="Cookie Policy">Cookie preferences</a>
							</nav>
							<nav className="nav social social-white text-md-end align-items-center">
								<a href="https://www.facebook.com/Raymedical/" target="_blank">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"></path>
									<path d="M13.643 9.36206C13.6427 9.05034 13.7663 8.75122 13.9864 8.53052C14.2065 8.30982 14.5053 8.18559 14.817 8.18506H15.992V5.23999H13.643C13.1796 5.24052 12.7209 5.33229 12.293 5.51013C11.8651 5.68798 11.4764 5.94841 11.1491 6.27649C10.8219 6.60457 10.5625 6.99389 10.3857 7.42224C10.209 7.85059 10.1183 8.30956 10.119 8.77295V11.718H7.769V14.663H10.119V21.817C11.2812 22.0479 12.4762 22.0604 13.643 21.854V14.663H15.992L17.167 11.718H13.643V9.36206Z" fill="currentColor"></path>
									</svg>
								</a>
								<a href="https://www.youtube.com/channel/UCNGnoi2JyiQVtagXIlhBQdw/videos?view_as=subscriber" target="_blank">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M21 6.30005C20.5 5.30005 19.9 5.19998 18.7 5.09998C17.5 4.99998 14.5 5 11.9 5C9.29999 5 6.29998 4.99998 5.09998 5.09998C3.89998 5.19998 3.29999 5.30005 2.79999 6.30005C2.19999 7.30005 2 8.90002 2 11.9C2 14.8 2.29999 16.5 2.79999 17.5C3.29999 18.5 3.89998 18.6001 5.09998 18.7001C6.29998 18.8001 9.29999 18.8 11.9 18.8C14.5 18.8 17.5 18.8001 18.7 18.7001C19.9 18.6001 20.5 18.4 21 17.5C21.6 16.5 21.8 14.9 21.8 11.9C21.8 9.00002 21.5 7.30005 21 6.30005ZM9.89999 15.7001V8.20007L14.5 11C15.3 11.5 15.3 12.5 14.5 13L9.89999 15.7001Z" fill="currentColor"></path>
									</svg>
								</a>
							</nav>
						</div>
					</div>
				</footer>
			</div>
		</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Home);
