import axios from 'axios';
import { Buffer } from "buffer";
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { AuthConstant, ProgramConstant } from '../redux/reducers';
import { RTButton } from '../libs/button';
import { API } from '../config/api';

const Login = ({ user }) => {

	const dispatch = useDispatch();
	const [ form, setForm ] = useState({ email : "", password : "" });
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	
	useEffect(() => {
		setProcess(false);
	},[]);

	const login = async () => {

		setErr("");

		setProcess(true);

		const ret = await axios.post(API.Login, { data : form });
		if(ret.data.data){
			if(ret.data.data.user.partnerId){
				localStorage.setItem("token", JSON.stringify(ret.data.data.token));
				localStorage.setItem("user", JSON.stringify(ret.data.data.user));
				dispatch({ type : AuthConstant.LOGIN_SUCCESS, 
					user : {...ret.data.data.user, token : ret.data.data.token.IdToken}
				});
			}else{
				setErr("Partner Only");
			}
		}else if(ret.data.error){
			setErr(ret.data.error.message);
		}

		setProcess(false);
	}

  	return (
	<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
				style={{ backgroundImage: "url(/media/illustrations/dozzy-1/14.png)" }}>
		<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 signin">
			<div className="mb-12 fs-1">
				RAYTEAMS
			</div>
			<div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
				<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" action="#">
					<div className="text-center mb-10">
						<h1 className="text-dark mb-3 cursor-pointer" onClick={() => history.push("/")}>Sign In to RAYTeams Partner</h1>
					</div>
					<div className="fv-row mb-10 fv-plugins-icon-container">
						<label className="form-label fs-6 fw-bolder text-dark">Email</label>
						<input 
							className="form-control form-control-lg form-control-solid" 
							type="email" 
							name="email" 
							value={form.email} 
							onChange={(e) => setForm({...form, email : e.target.value})}
							autoComplete="off" />
						<div className="fv-plugins-message-container invalid-feedback"></div>
					</div>
					<div className="fv-row mb-10 fv-plugins-icon-container">
						<div className="d-flex flex-stack mb-2">
							<label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
							{/*<a href="../../demo15/dist/authentication/layouts/basic/password-reset.html" className="link-primary fs-6 fw-bolder">Forgot Password ?</a>*/}
						</div>
						<input 
							className="form-control form-control-lg form-control-solid" 
							type="password" 
							name="password" 
							value={form.password} 
							onChange={(e) => setForm({...form, password : e.target.value})}
							autoComplete="off" />
						<div className="fv-plugins-message-container invalid-feedback"></div>
					</div>
					<div className="text-center">
						{err &&<div className="w-100 alert alert-warning">
							{err}
						</div>}
						<RTButton options={{
							label : process ? 'Please wait...' : 'Login',
							size : 'lg',
							ing : process,
							needValid : true,
							action : login
						}} valid={!process && form.email && form.password} css="w-100" />
					</div>
				</form>
			</div>
		</div>
		<div className="d-flex flex-center flex-column-auto p-10">
			<div className="d-flex align-items-center fw-bold fs-6">
				<a onClick={() => history.push("/")} className="text-muted text-hover-primary px-2 cursor-pointer">About</a>
				<a href="mailto:support@rayteams.com" className="text-muted text-hover-primary px-2">Contact</a>
				<a href="https://partner.rayteams.com/#contact-us" className="text-muted text-hover-primary px-2">Contact Us</a>
			</div>
		</div>
	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Login);
