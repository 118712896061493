import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { FaUser, FaStore, FaSave } from 'react-icons/fa';
import User from '../components/user';
import { API } from '../config/api';
import { RTButton } from '../libs/button';
import RTTable from '../libs/table';
import { RTGroup } from '../libs/string';
import { idData, fetchData } from '../libs/db';
import { Store } from '../store'

const CompPartnerUserList = ({ user, title, partner, allusers }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		getitems();
	},[partner]);

	const getitems = async () => {
		setItems(allusers.filter(x => x.partnerId === partner._id));
	}

	return <div className="card mb-5 shadow">
		<div className="card-header">
			<div className="card-title">{title}</div>
			<div className="card-toolbar">
				<RTButton options={{
					label : t('Add a member'),
					label : <>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor"></path>
							<rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"></rect>
					</svg> {t("Add a member")}</>,
					size : "sm",
					action : () => history.push("/addpartneruser?partner=" + partner._id)
				}} />
			</div>
		</div>
		<div className="card-body py-4">
			<table className="table align-middle table-row-bordered mb-0 fs-6 min-w-300px">
				<tbody className="fw-bold text-gray-600">
					{items.sort((a, b) => a.updated > b.updated ? -1 : 1).map((x, idx) => <tr className="" key={"srt-" + idx}>
						<td>
							<User _id={x._id} region={x.region} />
						</td>
						<td className="text-muted text-end">
							<div className="badge badge-secondary me-2">{moment(x.updated).format("L")}</div>
						</td>
					</tr>)}
				</tbody>
			</table>
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allusers =  state.ProgramReducer.allusers;

	return { user, allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompPartnerUserList);
