import { Store } from '../store'

export const RTSize = ({ bytes, decimals = 2 }) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return <>{parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} {sizes[i]}</>
}

export const nFormat = (s) => {
    return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const RTGroup = ({ gid, readonly }) => {
	const grp = Store.getState().ProgramReducer.allgroups.find(x => x._id === gid);
    return readonly ? <span>{grp?.name}</span> : <a href={"/group/" + gid}>{grp?.name}</a>;
}

export const RTPartner = ({ pid, readonly }) => {
	const partner = Store.getState().ProgramReducer.allpartner.find(x => x._id === pid);
    return readonly ? <span>{partner?.name}</span> : <a href={"/partner/" + pid}>{partner?.name}</a>;
}
