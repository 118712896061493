import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { FaCrown, FaStore } from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { RTPartnerSelect } from '../libs/input';
import { COUNTRIES } from '../config/country';
import { COLORS } from '../config/color';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTButton } from '../libs/button';
import { RTGroup } from '../libs/string';
import CompGroupList from '../components/grouplist';
import User from '../components/user';
import Lab from '../components/lab';
import Partner from '../components/partner';
import CompProjectList from '../components/projectlist';
import { Store } from '../store'
import AWS from 'aws-sdk';
import { aws_con, aws_con_admin, idData, fetchData, updateData } from '../libs/db';
import { COGNITO } from '../libs/cognito'

const UserPage = ({ my, allgroups, allusers }) => {

	const dispatch = useDispatch();
	const { _id } = useParams();

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ data, setData ] = useState({});
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ edit, setEdit ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
	
	const [ related, setRelated ] = useState([]);

	useEffect(() => {
		if(_id){
			getUser();
		}
	},[_id, allusers]);

	useEffect(() => {
		setPage();
		setData({
			name : info.name,
			partnerId : info.partnerId,
		});
	},[info]);

	const getUser = async () => {
		const userEx = allusers.find(x => x._id === _id && x.email);
		if(userEx){
			setInfo(userEx);
			return;
		}
		const userInfo = allusers.find(x => x._id === _id);

		setProcess(true);

		aws_con();
		//aws_con_admin();

		const params = {
			Username: _id,
			UserPoolId: COGNITO[userInfo.region || "ap-northeast-2"].poolId,
		};

		AWS.config.update({ region: userInfo.region || "ap-northeast-2" });

		var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
		try {
			var item = await cognitoidentityserviceprovider
				.adminGetUser(params)
				.promise();
			var ret = {};
			item?.UserAttributes.map((x) => {
				ret[x.Name.replace("custom:", "")] = x.Value;
			});
			ret._id = ret.sub;
			setInfo(ret);
			dispatch({ type : ProgramConstant.GETUSER, user : ret });
		} catch (err) {
			console.log(err);
		}

		setProcess(false);
	};

	const update = async () => {
		setProcess(true);

		console.log(data);

		const retUser = await updateData("rayteams-user", {
			_id : _id,
			sk : "info"
		}, {
			name : data.name,
			partnerId : data.partnerId
		});

		console.log(retUser);

		dispatch({ type : ProgramConstant.GETUSER, user : retUser });

		setProcess(false);

		setEdit(false);
	}

	const setPage = () => {
		const u = allusers.find(g => g._id === _id);
		setInfo(u);
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : <span>{u.groupId === info.partnerKey ? <FaCrown /> : <FaStore />} {u.name}</span>,
			caption : <div className="d-flex align-items-center">
				<span className="badge badge-secondary">{u.groupId === info.partnerKey ? "Partner Account" : "Lab Manager"}</span>
				<span className="ms-2">{u.email}</span>
			</div>,
			toolbar : <>
			</>
		}});
	}

  	return <div className="row">
		<div className="card mb-5 shadow">
			<div className="card-header">
				<div className="card-title">{t("Account Information")}</div>
				<div className="card-toolbar">
					{edit && <>
						<RTButton options={{
							label : t("Cancel"),
							color : COLORS.SECONDARY,
							size : "sm",
							action : () => setEdit(false)
						}} css="me-2" />
						<RTButton options={{
							label : t("Update"),
							size : "sm",
							action : () => update(),
							needValid : true
						}} valid={data.name} />
					</>}
					{!edit && <RTButton options={{
						label : t("Edit"),
						size : "sm",
						action : () => setEdit(true)
					}} />}
				</div>
			</div>
			<div className="card-body">
				<div className="mb-5">
					<label className="form-label fs-7 text-gray-900">{t("Email")}</label>
					<div className="text-gray-600">
						{info.email}
					</div>
				</div>
				<div className="mb-5">
					<label className="form-label fs-7 text-gray-900">{t("Name")}</label>
					<div className="text-gray-600">
						{!edit && info.name}
						{edit && <input 
							type="text" 
							className="form-control form-control-solid" 
							placeholder={t("Enter account name")}
							value={data.name}
							onChange={(e) => setData({...data, name : e.target.value})} />}
					</div>
				</div>
				{my.partnerKey === info.groupId && <div className="mb-5">
					<label className="form-label fs-7 text-gray-900">{t("Partner")}</label>
					<div className="text-gray-600">
						{!edit && <Partner _id={info.partnerId} />}
						{edit && <RTPartnerSelect options={{
							name : "partnerId",
							value : data.partnerId
						}} handleChanged={(e) => setData({...data, partnerId : e.target.value})} />}
					</div>
				</div>}
				{my.partnerKey !== info.groupId && <div className="mb-5">
					<label className="form-label fs-7 text-gray-900">{t("Lab")}</label>
					<div className="text-gray-600">
						{<Lab _id={info.groupId} />}
					</div>
				</div>}
				<div className="">
					<label className="form-label fs-7 text-gray-900">{t("Created")}</label>
					<div className="text-gray-600">
						{moment(info.created).format("L")}
					</div>
				</div>
			</div>
		</div>
	</div>
}

const mapState = (state) => {
	const my =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const allusers =  state.ProgramReducer.allusers;

	return { my, allgroups, allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(UserPage);
