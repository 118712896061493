import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { COUNTRIES } from '../config/country';
import { COLORS } from '../config/color';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTButton } from '../libs/button';
import { RTGroup } from '../libs/string';
import { pkData, idData, fetchData } from '../libs/db';
import CompGroupList from '../components/grouplist';
import CompDeviceInfo from '../components/deviceinfo';
import { Store } from '../store'

const Device = ({ user, allgroups, alldevices }) => {

	const dispatch = useDispatch();
	const { _id, _tabname } = useParams("overview");

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ edit, setEdit ] = useState(false);
	const [ search, setSearch ] = useState("");
	
	const [ related, setRelated ] = useState([]);

	useEffect(() => {
		if(_id){
			getitems(_id);
			setPage();
		}
	},[_id]);

	const setPage = () => {
		const item = alldevices.find(g => g.sn === _id);
		if(!item)
			return;
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : <>{t("Device")} : {item.sn}</>,
			caption : <>
				<span className="me-2 fw-bolder">
					{item.DeviceName}
				</span>
			</>
		}});
	}

	const getitems = async (_id) => {
		setErr("");

		setProcess(true);

		const ret = await pkData('rayteams-device', "sn", _id);
		setItems(ret.Items);
		setInfo(ret.Items.find(x => x.sk === "info"));

		setProcess(false);
	}

  	return <div className="row">
		<div className="col-6">
			<CompDeviceInfo device={alldevices.find(x => x.sn === _id)} title={"Information"} />
		</div>
		<div className="card col-6 mb-5 shadow">
			<div className="card-header">
				<div className="card-title">{t("Acqusitions")}</div>
			</div>
			<div className="card-body py-2 px-0">
				~
			</div>
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const alldevices =  state.ProgramReducer.alldevices;

	return { user, allgroups, alldevices };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Device);
