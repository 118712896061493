import axios from 'axios';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { COLORS } from '../config/color';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTButton } from '../libs/button';
import { RTGroup } from '../libs/string';
import { idData, fetchData } from '../libs/db';
import CompGroupList from '../components/grouplist';
import CompGroupUserList from '../components/groupuserlist';
import CompProjectList from '../components/projectlist';
import { Store } from '../store'

const Group = ({ user, allgroups, allpartner }) => {

	const dispatch = useDispatch();
	const { _id, _tabname } = useParams("overview");

	const [ info, setInfo ] = useState({});
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
	
	const [ related, setRelated ] = useState([]);

	useEffect(() => {
		if(_id){
			getitems(_id);
			setPage();
		}
	},[_id]);

	const setPage = () => {
		const grp = allgroups.find(g => g._id === _id);
		const ptn = allpartner.find(g => g._id === grp.partnerId);
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : <>
				Lab : {grp.name}
			</>,
			caption : <>
				<span className="me-2 fw-bolder">
					{ptn?.name},
				</span>
				<span className="me-2 fw-bolder">
					{COUNTRIES.find(x => x.countryCode === grp.countryCode)?.name}
				</span>
				{grp.address}
			</>,
			toolbar : <>
				<RTButton options={{
					label : "Edit",
					color : COLORS.WARNING,
					action : () => history.push("/updatelab/" + _id)
				}} css="me-2" />
			</>
		}});
	}

	const getitems = async (_id) => {
		const grp = allgroups.find(g => g._id === _id);

		setInfo(grp || {});

		setErr("");

		setProcess(true);

		const ret = await idData('rayteams-group', _id);
		console.log(ret.Items);
		setItems(ret.Items);

		setRelated(ret.Items.filter(x => x.sk.includes("sct:") || x.sk.includes("rct:")));

		setProcess(false);
	}

	const getGroup = (glist) => {
		return glist.map(x => ({...allgroups.find(g => g._id === x), _id : x}))
	}
	
  	return <div className="row">
		<div className="col">
			<CompGroupUserList title={"Users"} group={info} />
		</div>
		<div className="col">
			<CompProjectList title={"Orders"} group={info} />
		</div>
		{/* <CompGroupList title={"Related Group"} groups={getGroup([...new Set(related.map(x => x.sk.split(":")[2]))])} />*/}
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const allpartner =  state.ProgramReducer.allpartner;
	return { user, allgroups, allpartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Group);
