exports.PROD_NODE_ENVS = ["production", "development"];
exports.DEFAULT_SET = {
  TEAMS: {
    region: "ap-northeast-2",
    arn: "arn:aws:cognito-idp:ap-northeast-2:408629215690:userpool/ap-northeast-2_Tj3PQgdok",
    cogPoolId: "ap-northeast-2:2d483ae2-7693-4568-9646-cecb18d6b056",
    cogId: "cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_Tj3PQgdok",
    poolId: "ap-northeast-2_Tj3PQgdok",
    appId: "37ev8rm4mvtnb65hfu81l0k98b",
  },
  TEAMS_TEST: {
    region: "ap-northeast-2",
    arn: "arn:aws:cognito-idp:ap-northeast-2:408629215690:userpool/ap-northeast-2_ssAhtjw8e",
    cogPoolId: "ap-northeast-2:337d466b-bd2f-4ea0-a3c8-4290bb970196",
    cogId: "cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_ssAhtjw8e",
    poolId: "ap-northeast-2_ssAhtjw8e",
    appId: "3q5fbq701jabltjr3o14s9c5s8",
  },
};
exports.COGNITO = {
  "ap-northeast-2": {
    arn: "arn:aws:cognito-idp:ap-northeast-2:408629215690:userpool/ap-northeast-2_Tj3PQgdok",
    cogPoolId: "ap-northeast-2:2d483ae2-7693-4568-9646-cecb18d6b056",
    cogId: "cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_Tj3PQgdok",
    poolId: "ap-northeast-2_Tj3PQgdok",
    appId: "37ev8rm4mvtnb65hfu81l0k98b",
  },
  "us-east-1": {
    arn: "arn:aws:cognito-idp:us-east-1:408629215690:userpool/us-east-1_7FtyKhYrk",
    cogPoolId: "us-east-1:1501fe61-e804-4bad-908d-2a689b164780",
    cogId: "cognito-idp.us-east-1.amazonaws.com/us-east-1_7FtyKhYrk",
    poolId: "us-east-1_7FtyKhYrk",
    appId: "1egig6vs9lm3imifqucnegh52n",
  },
  "ap-south-1": {
    arn: "arn:aws:cognito-idp:ap-south-1:408629215690:userpool/ap-south-1_emEwUjkLu",
    cogPoolId: "ap-south-1:63db82a7-544d-4095-8ff6-430cbe427bcb",
    cogId: "cognito-idp.ap-south-1.amazonaws.com/ap-south-1_emEwUjkLu",
    poolId: "ap-south-1_emEwUjkLu",
    appId: "7kpot1lfck2g02564bma6ngq5r",
  },
  "ap-southeast-1": {
    arn: "arn:aws:cognito-idp:ap-southeast-1:408629215690:userpool/ap-southeast-1_tQNCR71IO",
    cogPoolId: "ap-southeast-1:a4e5d97c-eeff-4f8f-bbd3-b06eeed90287",
    cogId: "cognito-idp.ap-southeast-1.amazonaws.com/ap-southeast-1_tQNCR71IO",
    poolId: "ap-southeast-1_tQNCR71IO",
    appId: "ml92ri2e81f4m1ps69ngv3p7u",
  },
  "ap-southeast-2": {
    arn: "arn:aws:cognito-idp:ap-southeast-2:408629215690:userpool/ap-southeast-2_egYpW9mSB",
    cogPoolId: "ap-southeast-2:10c6b2c1-edc4-48ba-b43e-c6f454bad2b8",
    cogId: "cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_egYpW9mSB",
    poolId: "ap-southeast-2_egYpW9mSB",
    appId: "35m3keiu8iabpkohe8tsnn895m",
  },
  "ap-northeast-1": {
    arn: "arn:aws:cognito-idp:ap-northeast-1:408629215690:userpool/ap-northeast-1_stPGXQLFD",
    cogPoolId: "ap-northeast-1:73b075e9-28a6-4cb4-9a83-14a616423da0",
    cogId: "cognito-idp.ap-northeast-1.amazonaws.com/ap-northeast-1_stPGXQLFD",
    poolId: "ap-northeast-1_stPGXQLFD",
    appId: "4g1p1n293gitle63hr9gehrr88",
  },
  "ca-central-1": {
    arn: "arn:aws:cognito-idp:ca-central-1:408629215690:userpool/ca-central-1_Z2RBOgxr8",
    cogPoolId: "ca-central-1:aff4dfe0-cdd1-4aa5-a18a-ee4a871af7a1",
    cogId: "cognito-idp.ca-central-1.amazonaws.com/ca-central-1_Z2RBOgxr8",
    poolId: "ca-central-1_Z2RBOgxr8",
    appId: "1iaqgus395hlpjptag6829h1a7",
  },
  "eu-central-1": {
    arn: "arn:aws:cognito-idp:eu-central-1:408629215690:userpool/eu-central-1_CZ0D0cwpA",
    cogPoolId: "eu-central-1:74bee28b-70c9-4dbe-9eb7-ee966873ce3a",
    cogId: "cognito-idp.eu-central-1.amazonaws.com/eu-central-1_CZ0D0cwpA",
    poolId: "eu-central-1_CZ0D0cwpA",
    appId: "27mbr7pjqgohpgkc44u3e6o3vf",
  },
  "eu-west-1": {
    arn: "arn:aws:cognito-idp:eu-west-1:408629215690:userpool/eu-west-1_IMHERQDhb",
    cogPoolId: "eu-west-1:5ca8377f-a4ba-4954-9abb-514029f65793",
    cogId: "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_IMHERQDhb",
    poolId: "eu-west-1_IMHERQDhb",
    appId: "45v7n55ptpljoh18csk112c748",
  },
  "eu-west-2": {
    arn: "arn:aws:cognito-idp:eu-west-2:408629215690:userpool/eu-west-2_YweFJEiy5",
    cogPoolId: "eu-west-2:855312b6-f5c6-4bcc-a1ae-7e1cc08b631b",
    cogId: "cognito-idp.eu-west-2.amazonaws.com/eu-west-2_YweFJEiy5",
    poolId: "eu-west-2_YweFJEiy5",
    appId: "45evn69ujnctghfb9g828b34tq",
  },
  "eu-west-3": {
    arn: "arn:aws:cognito-idp:eu-west-3:408629215690:userpool/eu-west-3_peBFtXYrW",
    cogPoolId: "eu-west-3:262de0dc-f44a-4e61-afb1-545653dfe586",
    cogId: "cognito-idp.eu-west-3.amazonaws.com/eu-west-3_peBFtXYrW",
    poolId: "eu-west-3_peBFtXYrW",
    appId: "1nn4hau1i0s2erbg7b7ts5i8eg",
  },
  "eu-north-1": {
    arn: "arn:aws:cognito-idp:eu-north-1:408629215690:userpool/eu-north-1_6MIVhRECL",
    cogPoolId: "eu-north-1:23e6bbba-990d-4099-bfee-e2a140329a0b",
    cogId: "cognito-idp.eu-north-1.amazonaws.com/eu-north-1_6MIVhRECL",
    poolId: "eu-north-1_6MIVhRECL",
    appId: "2hqlpdkooppetuonm5e10ob75f",
  },
  "sa-east-1": {
    arn: "arn:aws:cognito-idp:sa-east-1:408629215690:userpool/sa-east-1_Xe8DYswQJ",
    cogPoolId: "sa-east-1:24de6538-69d1-4f03-acf0-a0d7266d09ed",
    cogId: "cognito-idp.sa-east-1.amazonaws.com/sa-east-1_Xe8DYswQJ",
    poolId: "sa-east-1_Xe8DYswQJ",
    appId: "2etj5uaid3ppj9vp34qb5jfi4e",
  },
  "ap-east-1": {
    arn: "arn:aws:cognito-idp:ap-northeast-2:408629215690:userpool/ap-northeast-2_hTDip6auI",
    cogPoolId: "ap-northeast-2:ce7b8164-159b-4ae5-bb8f-6cf25bfcabf3",
    cogId: "cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_hTDip6auI",
    poolId: "ap-northeast-2_hTDip6auI",
    appId: "4rmikvculq3rohrdvji5fe5mds",
  },
};
// 코그니토 유저 풀이 다른 리전에 존재하는 경우에만 정의, AWS.config에 사용됨, auth table 관련 region에서는 사용해선 않됨.
// CognitoIdentityServiceProvider를 선언하여 사용하기 전에 선언되어야함.
// 테이블에 접근하는 경우에는 원래 리전으로 변경해 줘야함. (getDdb()가 있다면 리전을 재정의해줌)
exports.CONFIG_REGION_SET = {
  "ap-east-1": "ap-northeast-2",
};
