import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTGroup } from '../libs/string';
import { FaUser, FaStore, FaSave } from 'react-icons/fa';
import { idData, fetchData } from '../libs/db';
import { RTButton } from '../libs/button';
import { Store } from '../store'
import { RTPanelNoData } from '../libs/panel';

const CompGroupList = ({ user, title, groups, partner }) => {

	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);

	useEffect(() => {
	},[]);

	return <div className="card mb-5 shadow">
		<div className="card-header">
			<div className="card-title">{title}</div>
			<div className="card-toolbar">
				<RTButton options={{
					label : <>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-add-lab">
							<path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="#5014d0"/>
						</svg> {t("Add a lab")}</>,
					size : "sm",
					action : () => history.push("/addlab?partner=" + partner._id)
				}} />
			</div>
		</div>
		<div className="card-body py-4">
			{groups.length > 0 && <table className="table align-middle table-row-bordered mb-0 fs-6 min-w-300px">
				<tbody className="fw-bold text-gray-600">
					{groups.map((x, idx) => <tr className="" key={"srt-" + idx}>
						<td><a href={"/group/" + x._id}>{x.name}</a></td>
						<td className="text-muted text-end">{COUNTRIES.find(c => c.countryCode === x.countryCode)?.name}</td>
					</tr>)}
				</tbody>
			</table>}
			{groups.length === 0 && <RTPanelNoData />}
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompGroupList);
