import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import Clinic from '../components/clinic';
import { API } from '../config/api';
import { RTPartnerSelect, RTSelect, RTSelectMulti } from '../libs/input';
import { COLORS } from '../config/color';
import RTTable from '../libs/table';
import { RTSize, RTGroup, RTPartner } from '../libs/string';
import { RTButton } from '../libs/button';
import { idData, fetchData, updateData } from '../libs/db';
import { Store } from '../store'

const CompDeviceInfo = ({ user, title, device, allusers, allgroups }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(device){
			setData({
				isactive : device.isactive,
				labs : device.labs,
				partnerId : device.partnerId
			});
		}
	}, [device]);

	const update = async () => {
		setProcess(true);

		const ret = await updateData("rayteams-device", { sn : device.sn, sk : "info" }, data);
		dispatch({ 
			type : ProgramConstant.UPDATEDEVICE, 
			item : ret
		});

		setProcess(false);

		setEdit(false);
	}

	const getLabs = () => {
		const gItems = [];
		device.labs?.map((x, idx) => {
			gItems.push(<RTGroup gid={x} readonly={edit} />);
			if(idx < device.labs.length - 1)
				gItems.push(<span>, </span>);
		});
		return gItems;
	}

	return <div className="card mb-5 shadow">
		<div className="card-header">
			<div className="card-title">{title}</div>
			<div className="card-toolbar">
				{edit && <>
					<RTButton options={{
						label : t("Cancel"),
						color : COLORS.SECONDARY,
						size : "sm",
						action : () => setEdit(false)
					}} css="me-2" />
					<RTButton options={{
						label : t("Update"),
						size : "sm",
						action : () => update()
					}} />
				</>}
				{!edit && <RTButton options={{
					label : t("Edit"),
					size : "sm",
					action : () => setEdit(true)
				}} />}
			</div>
		</div>
		{device && <div className="card-body">
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Partner")}</label>
				<div className="text-gray-600">
					{!edit && <RTPartner pid={device.partnerId} readonly={true} />}
					{edit && <RTPartnerSelect options={{
						name : "partnerId",
						value : data.partnerId
						}} handleChanged={(e) => setData({...data, partnerId : e.target.value})} />}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Lab")}</label>
				<div className="text-gray-600">
					{!edit && getLabs()}
					{edit && <RTSelectMulti options={{
						name : "labs",
						value : data.labs,
						list : allgroups.filter(x => x.partnerId === data.partnerId).map(x => ({ value : x._id, label : x.name }))
						}} handleChanged={(e) => setData({...data, labs : e.target.value})} />}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Sharing")}</label>
				<div className="text-gray-600">
					{!edit && (data.isactive ? <><FaCheckCircle size="20" /> {t("Enabled")}</> : <><FaStopCircle size="20" /> {t("Disabled")}</>)}
					{edit && <div className="form-check form-switch form-check-custom form-check-solid">
						<input className="form-check-input" 
							type="checkbox" 
							value={data.isactive} 
							checked={data.isactive ? "checked" : ""} 
							onChange={() => setData({...data, isactive : !data.isactive})}/>
						<label className="form-check-label fs-6 text-gray-800">
						</label>
					</div>}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Clinic")}</label>
				<div className="text-gray-600">
					<Clinic cid={device.ownerId} />
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Created")}</label>
				<div className="text-gray-600">
					{moment(device.created).format("LL")}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Activated")}</label>
				<div className="text-gray-600">
					{moment(device.activated).format("LL")}
				</div>
			</div>
		</div>}
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allusers =  state.ProgramReducer.allusers;
	const allgroups =  state.ProgramReducer.allgroups;

	return { user, allusers, allgroups };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompDeviceInfo);
