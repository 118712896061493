import axios from 'axios';
import { v4 } from 'uuid';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES, getRegion } from '../config/country';
import { API } from '../config/api';
import { COLORS } from '../config/color';
import RTTable from '../libs/table';
import { RTGroup } from '../libs/string';
import { RTCountrySelect } from '../libs/input';
import { idData, fetchData, insertData, updateData } from '../libs/db';
import { RTButton } from '../libs/button';
import PartnerTree from '../components/treepartners';
import { Store } from '../store'

const GroupCreate = ({ user, allgroups, mypartner, allpartner }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [ partner, setPartner ] = useState({});
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");

	useEffect(() => {
		init();
	},[mypartner, _id]);

	const init = async () => {
		const qs = queryString.parse(window.location.search);
		if(qs?.partner){
			setPartner(allpartner.find(x => x._id === qs?.partner));
			return;
		}	
		if(_id){
			const grp = allgroups.find(g => g._id === _id);
			setPartner(allpartner.find(x => x._id === grp.partnerId))
			setData(grp);
			dispatch({ type : ProgramConstant.PAGE, item : {
				label : t("Edit") + " " + grp.name + " " + t("information"),
				caption : t("Management lab information")
			}});
		}else{
			setPartner(mypartner);
			dispatch({ type : ProgramConstant.PAGE, item : {
				label : t("Add a lab"),
				caption : t("Add a lab")
			}});
		}
	}

	const createGroup = async () => {
		setProcess(true);
		
		//user create
		const region = getRegion(data.countryCode);
		const groupId = v4();
		const newUser = {
			name : data.username,
			email : data.useremail,
			password : data.userpassword,
			region : region.region,
			grouptype : "lab",
			groupId,
			partnerId : partner._id,
			partnerKey : user.partnerKey,
		}
		var retMember = await axios.post("https://api-" + user.region + "-user.rayteams.com/user",  {
			data : newUser } , {
				headers : { 
					'x-ray-device' : "RAYLINK" 
				} 
			});
		if(retMember.data.status === "fail"){
			setProcess(false);
			setErr(retMember.data.error);
			return;
		}

		//group create
		const newGroup = {
			_id: groupId,
			sk: "info",
			address: data.address,
			coords: [ 0, 0 ],
			countryCode: data.countryCode,
			created: new Date().getTime(),
			grouptype: ["lab"],
			gSiteId: "",
			hasMember: true,
			isDel: false,
			name: data.name,
			orgId: "",
			origin: "",
			region: region.region,
			tel: "",
			updated: new Date().getTime(),
			partnerId : partner._id,
			partnerKey : user.partnerKey,
			zipcode: ""
		};

		const retgroup = await insertData("rayteams-group", newGroup);

		dispatch({ type : ProgramConstant.ADDUSER, user : retMember.data.data });
		dispatch({ type : ProgramConstant.GROUPADD, group : newGroup });

		setProcess(false);

		history.push("/labs");
	}

	const updateGroup = async () => {
		setProcess(true);
		
		const params = {...data};
		delete params._id;
		delete params.sk;
		const newGroup = {...params,
			address: data.address,
			countryCode: data.countryCode,
			updated: new Date().getTime(),
			name: data.name,
			partnerId : partner._id
		};

		const retgroup = await updateData("rayteams-group", {
			_id,
			sk : "info"
		}, newGroup);

		dispatch({ type : ProgramConstant.GROUPUPDATE, group : {...newGroup, _id} });

		setProcess(false);

		history.push(_id ? "/group/" + _id : "/labs");
	}
	
  	return <>
		{err && <div className="alert alert-warning shadow">{err}</div>}
		<div className="card mb-5 shadow">
			<div className="card-header">
				<div className="card-title">
					<input 
						type="text" 
						className="w-300px form-control form-control-solid" 
						placeholder="Enter lab name" 
						value={data.name}
						onChange={(e) => setData({...data, name : e.target.value})} />
					<span className="ms-2"> belong to a partner '{partner.name}'</span>.
				</div>
				<div className="card-toolbar">
					{!process && <RTButton options={{
						label : t("Cancel"),
						color : COLORS.SECONDARY,
						action : () => history.push("/labs")
					}} css="me-2" />}
					<RTButton options={{
						label : process ? t('Please wait...') : (_id ? t("Update") : t("Create")),
						ing : process,
						action : () => _id ? updateGroup() : createGroup(),
						needValid : true
					}} valid={_id ? 
							(!process && !!(data.name && data.countryCode && data.address)) : 
							(!process && !!(data.name && data.countryCode && data.address && data.username && data.useremail && data.userpassword))} />
				</div>
			</div>
			<form>
			<div className="card-body row">
				<div className="mb-5 col-6">
					<label className="form-label fs-3">{t("Choose Partner")}</label>
					<div>
						<PartnerTree 
							selected={partner._id}
							onChanged={(e, id) => setPartner(allpartner.find(x => x._id === id))}/>
					</div>
				</div>
				<div className="col-6">
					<div className="mb-5">
						<label className="form-label">{t("Country")}</label>
						<RTCountrySelect options={{
							name : "countryCode",
							value : data.countryCode
						}} handleChanged={(e) => setData({...data, countryCode : e.target.value})} />
					</div>
					<div className="mb-5 col-12">
						<label className="form-label">{t("Address")}</label>
						<input 
							type="text" 
							className="form-control form-control-solid" 
							placeholder={t("Lab address")}
							value={data.address}
							onChange={(e) => setData({...data, address : e.target.value})}/>
					</div>
					{!_id && <>
						<div className="mb-5 mt-10">
							<label className="form-label fs-3">{t("Manager Account")}</label>
						</div>
						<div className="mb-5">
							<label className="form-label">{t("Manager Name")}</label>
							<input 
								type="text" 
								className="form-control form-control-solid" 
								placeholder={t("Manager Name")}
								value={data.username}
								onChange={(e) => setData({...data, username : e.target.value})}/>
						</div>
						<div className="mb-5">
							<label className="form-label">{t("Manager Email")}</label>
							<input 
								type="email" 
								className="form-control form-control-solid" 
								placeholder={t("Manager Email")}
								value={data.useremail}
								onChange={(e) => setData({...data, useremail : e.target.value})}/>
						</div>
						<div className="mb-5">
							<label className="form-label">{t("Manager Password")}</label>
							<input type="password" 
								className="form-control form-control-solid" 
								placeholder={t("Password")}
								value={data.userpassword || ""}
								onChange={(e) => setData({...data, userpassword : e.target.value})}/>
						</div>
						<div className="mb-5">
							<label className="form-label">{t("Manager Password Confirm")}</label>
							<input type="password" 
								className="form-control form-control-solid" 
								placeholder={t("Password")}
								value={data.userpassword2 || ""}
								onChange={(e) => setData({...data, userpassword2 : e.target.value})}/>
						</div>
					</>}
				</div>
			</div>
			</form>
		</div>
		
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const mypartner =  state.ProgramReducer.mypartner;
	const allpartner =  state.ProgramReducer.allpartner;

	return { user, allgroups, mypartner, allpartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(GroupCreate);
