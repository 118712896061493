import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTGroup } from '../libs/string';
import { idData, fetchData } from '../libs/db';
import { Store } from '../store'
import { RTPanelNoData } from '../libs/panel';

const CompProjectList = ({ user, title, group }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		getitems();
	},[group]);

	const getitems = async () => {

		setProcess(true);

		console.log(group._id);

		const ret = await fetchData('rayteams-project', {
			sk : { 
				ComparisonOperator: "EQ",
				AttributeValueList: ["project"] 
			},
			partnerId : { 
				ComparisonOperator: "EQ",
				AttributeValueList: [group.partnerId] 
			},
			labId : { 
				ComparisonOperator: "EQ",
				AttributeValueList: [group._id] 
			},
		});
		setItems(ret.Items);

		setProcess(false);
	}

	return <div className="card mb-5 shadow">
		<div className="card-header">
			<div className="card-title">{title}</div>
			<div className="card-toolbar">Total : {items.length}</div>
		</div>
		<div className="card-body">
			{items.length > 0 && <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
				<tbody className="fw-bold text-gray-600">
					{items.sort((a, b) => a.updated > b.updated ? -1 : 1).map((x, idx) => <tr className="" key={"srt-" + idx}>
						<td>
							{x._id}
							<span className="badge badge-secondary ms-4">{x.projecttype}</span>
							<div className="me-2">
								<i>{x.ownerGroupId}</i>
							</div>
						</td>
						<td className="text-muted text-end">
							<div className="badge badge-secondary me-2">{moment(x.updated).format("L")}</div>
						</td>
					</tr>)}
				</tbody>
			</table>}
			{items.length === 0 && <RTPanelNoData />}
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompProjectList);
