import * as AWS from 'aws-sdk'
import { Store } from '../store'
import { Buffer } from "buffer";

export const aws_con_admin = () => {

	const uData = JSON.parse(localStorage.getItem('user'));
	if(!uData)
		return;

	const be = Buffer.from(uData.pc, "base64").toString('utf8');
	const arr = be.split("|rayteams|");

	const configuration: ConfigurationOptions = {
		region: 'ap-northeast-2',
		secretAccessKey: "Ux0qsDvdDm53vTuHPst9/ePJMIoG0zg/zsV2WLga",
		accessKeyId: "AKIAV6JBTAHFLLEA5APR"
	}

	AWS.config.update(configuration)
}
export const aws_con = () => {

	const uData = JSON.parse(localStorage.getItem('user'));
	if(!uData)
		return;

	const be = Buffer.from(uData.pc, "base64").toString('utf8');
	const arr = be.split("|rayteams|");

	const configuration: ConfigurationOptions = {
		region: 'ap-northeast-2',
		secretAccessKey: arr[1],
		accessKeyId: arr[0]
	}

	AWS.config.update(configuration)
}

export const pkData = async (tableName, key, val) => {

	aws_con();

	const docClient = new AWS.DynamoDB.DocumentClient()
	const KeyCon = {};
	KeyCon[key] = {
		ComparisonOperator: "EQ",
		AttributeValueList: [val],
	}

    var params = {
        TableName: tableName,
		KeyConditions: KeyCon,
		ScanIndexForward: false,
    }

    return await docClient.query(params).promise();
}

export const idData = async (tableName, id) => {

	aws_con();

	const docClient = new AWS.DynamoDB.DocumentClient()

    var params = {
        TableName: tableName,
		KeyConditions: {
		  _id: {
			ComparisonOperator: "EQ",
			AttributeValueList: [id],
		  }
		},
		ScanIndexForward: false,
    }

    return await docClient.query(params).promise();

}

export const fetchData = async (tableName, sfilter) => {

	aws_con();

    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        ScanFilter : sfilter
    }
    const scanResults = { Items : [], Count : 0 };
    let items;
    do{
        items =  await docClient.scan(params).promise();
        items.Items.forEach((item) => { scanResults.Items.push(item); scanResults.Count++; });
        params.ExclusiveStartKey  = items.LastEvaluatedKey;
    }while(typeof items.LastEvaluatedKey !== "undefined");

    return scanResults;
}

export const insertData = async (tableName, data) => {

	aws_con();

	const docClient = new AWS.DynamoDB.DocumentClient()

    var params = {
        TableName: tableName,
		Item : data
    }

    await docClient.put(params).promise();

	return data;
}


export const updateData = async (tableName, key, data) => {

	aws_con();

	var attr = {};
	Object.keys(data).map((x) => {
	  attr[x] = {
		Action: "PUT",
		Value: data[x],
	  };
	});

	const docClient = new AWS.DynamoDB.DocumentClient()

    var params = {
        TableName: tableName,
		Key: key, 
		AttributeUpdates: attr,
		ReturnValues : "ALL_NEW"
    }

    const upData = await docClient.update(params).promise();

	return upData.Attributes;
}
