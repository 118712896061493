import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { COLORS } from '../config/color';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTButton } from '../libs/button';
import { RTGroup } from '../libs/string';
import { idData, fetchData } from '../libs/db';
import CompGroupList from '../components/grouplist';
import CompProjectList from '../components/projectlist';
import { Store } from '../store'

const Clinic = ({ user, allclinics, cid, card = false }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(cid){
			getitem();
		}
	},[cid]);

	const getitem = async () => {
		const clinic = allclinics.find(x => x._id === cid);
		if(clinic){
			setInfo(clinic);
			return;
		}

		setProcess(true);

		const retClinic = await fetchData('rayteams-group', { 
			_id : { ComparisonOperator: "EQ", AttributeValueList : [cid] },
			sk : { ComparisonOperator: "EQ", AttributeValueList : ["info"] },
		});

		if(retClinic.Items.length > 0){
			setInfo(retClinic.Items[0]);
			dispatch({ type : ProgramConstant.GETCLINIC, clinic : retClinic.Items[0] });
		}else{
			setInfo({});
		}

		setProcess(false);
	}
	
  	return <>
		{!card && <>
			<div>
				<a href={"/clinic/" + info._id} className="me-2">{info.name}</a>
				<span className='text-gray-600'>{COUNTRIES.find(x => x.countryCode === info.countryCode)?.name}</span>
			</div>
		</>}
		{card && <></>}
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allclinics =  state.ProgramReducer.allclinics;
	return { user, allclinics };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Clinic);
